<style>

</style>
<script>
import { VclBulletList } from 'vue-content-loading';
import simplebar from "simplebar-vue";
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id'],
  components: {
    VclBulletList,
    simplebar
  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/activities`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.activities = data.activities;
          if(this.activities.length > 0) this.delayedScroll();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    delayedScroll() {
      setTimeout(() => {
        let element = this.$refs.activityStreamSimple;
        if(element.scrollElement.offsetHeight >= 250 && element.scrollElement.scrollHeight > element.scrollElement.offsetHeight) {
          element.scrollElement.scrollTo(0, element.scrollElement.scrollHeight);
        }
      }, 150);
    },
    openActivities(event) {
      this.$parent.$emit('tabChangeRequest', 'ProfileActivities');
      event.preventDefault();
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      activities: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="card">
        <template v-if="!activities.length">
          <div class="card-body">
            <div class="text-center allign-center mt-4 mb-4">
              <h3 class="text-uppercase text-muted">{{ $t('profile.activities.none') }}</h3>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card-body">
            <simplebar style="max-height: 250px;" ref="activityStreamSimple">
            <ul class="verti-timeline list-unstyled">
              <li class="event-list" v-for="activity in activities" :key="activity.id">
                <div class="event-timeline-dot">
                  <i class="fad fa-circle"></i>
                </div>
                <div class="media">
                  <div class="media-body">
                    <div>
                      <h5 class="font-size-15 text-dark d-inline">
                        <small class="text-uppercase mr-1">
                          {{ $t(`profile.activities.${activity.action}`) }}
                        </small>
                        <span>{{ activity.server | truncate(42) }}</span>
                        <small class="float-right d-inline">
                          <i class="fad fa-clock"></i>
                          {{ $d(parseDate(activity.date), 'datetime', getDTLocale()) }}
                        </small>
                      </h5>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            </simplebar>
          </div>
          <div class="card-footer">
            <a href="#" v-on:click="openActivities($event)" class="text-muted text-uppercase text-center">
              <h4>
                {{ $t('profile.activities.all') }}
              </h4>
            </a>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
      </div>
    </template>
  </div>
</template>
